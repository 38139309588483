import React, { useEffect, useRef, useState, useContext } from 'react';
import AISampleDialog from '../SoundBrowser/AISampleDialog';
import WaveSurfer from 'wavesurfer.js';
import { JUCESend, JUCEReceive, isJUCE } from '../../context/JUCE';
import { getDownloadURL, likeSound, unlikeSound, purchaseSound, fetchSoundById } from '../../api/APIManager';
import { DEFAULT_SOUND_IMAGE } from '../../api/APIConfig';
import SpinnerFull from '../SpinnerFull';
import Spinner from '../Spinner';
import { SoundCardDialogs } from '../SoundBrowser/SoundCardDialogs';
import { Analytics } from '../../api/Analytics/AnalyticsService';
import ActionButton from '../ActionButton';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

import {
  PlayIcon,
  PauseIcon,
  LikeIcon,
  MoreIcon,
  DownloadIcon,
  DragIcon,
  CheckIcon,
  TokenBadge,
  MaximizeIcon
} from '../../assets/Icons';
import Lottie from 'lottie-react';
import waveformMinimizeAnimation from '../../assets/animations/waveform_minimize.json';
import { ChevronDown } from 'lucide-react';

const MOBILE_PLAYER_HEIGHT = 80;  // Height for mobile view
const DESKTOP_PLAYER_HEIGHT = 80; // Standard height for audio player
const PLAYER_WIDTH = 720; // Width for the floating player

const VolumeIcon = ({ volume }) => {
  if (volume === 0) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/>
      </svg>
    );
  } else if (volume < 50) {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02z"/>
      </svg>
    );
  } else {
    return (
      <svg className="w-4 h-4" viewBox="0 0 24 24" fill="currentColor">
        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
      </svg>
    );
  }
};

const VolumeControl = ({ volume, onChange, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [previousVolume, setPreviousVolume] = useState(volume);
  const controlRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (controlRef.current && !controlRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleVolumeChange = (e) => {
    const newVolume = parseInt(e.target.value);
    onChange(newVolume);
  };

  const toggleMute = () => {
    if (volume > 0) {
      setPreviousVolume(volume);
      onChange(0);
    } else {
      onChange(previousVolume);
    }
  };

  const renderVolumeSlider = () => (
    <div className={`flex items-center md:pr-4 space-x-2 ${isMobile ? 'py-3 px-4' : ''}`}>
      <input
        type="range"
        min="0"
        max="100"
        value={volume}
        onChange={handleVolumeChange}
        onMouseDown={() => setIsDragging(true)}
        onMouseUp={() => setIsDragging(false)}
        onTouchStart={() => setIsDragging(true)}
        onTouchEnd={() => setIsDragging(false)}
        className="w-24 h-1.5 bg-white/20 rounded-lg appearance-none cursor-pointer
          [&::-webkit-slider-thumb]:appearance-none
          [&::-webkit-slider-thumb]:w-4
          [&::-webkit-slider-thumb]:h-4
          [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:bg-text-primary
          [&::-webkit-slider-thumb]:shadow-md
          [&::-webkit-slider-thumb]:hover:scale-110
          [&::-webkit-slider-thumb]:transition-all
          [&::-moz-range-thumb]:w-4
          [&::-moz-range-thumb]:h-4
          [&::-moz-range-thumb]:rounded-full
          [&::-moz-range-thumb]:bg-text-primary
          [&::-moz-range-thumb]:border-none
          [&::-moz-range-thumb]:shadow-md
          [&::-moz-range-thumb]:hover:scale-110
          [&::-moz-range-thumb]:transition-all"
      />
      {isMobile && (
        <span className="text-xs text-text-secondary min-w-[2.5rem]">
          {volume}%
        </span>
      )}
    </div>
  );

  if (isMobile) {
    return (
      <div ref={controlRef} className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <VolumeIcon volume={volume} />
        </button>
        {isOpen && (
          <div className="absolute bottom-full right-0 mb-2 bg-bg-secondary/95 backdrop-blur-lg rounded-lg shadow-lg py-2 min-w-[160px] border border-white/10">
            {renderVolumeSlider()}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={toggleMute}
        className="p-2 hover:bg-white/10 rounded-full transition-colors"
      >
        <VolumeIcon volume={volume} />
      </button>
      {renderVolumeSlider()}
    </div>
  );
};

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    
    const handler = (event) => {
      setMatches(event.matches);
    };

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }

    setMatches(mediaQuery.matches);

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handler);
      } else {
        mediaQuery.removeListener(handler);
      }
    };
  }, [query]);

  return matches;
};

const PrevIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z"/>
  </svg>
);

const NextIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
    <path d="M16 6h2v12h-2zm-3.5 6L4 18V6z"/>
  </svg>
);

const formatTime = (timeInSeconds) => {
  if (isNaN(timeInSeconds)) return '0:00';
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const AudioPlayer = ({ sound: initialSound, isPlaying, onPlayPause, onEnded, onNext, onPrevious, isMinimized: externalIsMinimized, setIsMinimized: externalSetIsMinimized }) => {
  const [sound, setSound] = useState(initialSound);
  const [internalIsMinimized, setInternalIsMinimized] = useState(false);
  
  // Use external minimized state if provided, otherwise use internal state
  const isMinimized = externalIsMinimized !== undefined ? externalIsMinimized : internalIsMinimized;
  const setIsMinimized = externalSetIsMinimized || setInternalIsMinimized;
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [continueToPurchase, setContinueToPurchase] = useState(false);
  const [continueToDownload, setContinueToDownload] = useState(false);
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const lottieRef = useRef(null);
  const [isWaveSurferReady, setIsWaveSurferReady] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [volume, setVolume] = useState(100);
  const [isLiked, setIsLiked] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [isAISampleDialogOpen, setIsAISampleDialogOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const lastJUCEPosition = useRef(0);
  const buttonClass = "h-8 flex flex-col items-center justify-center text-white rounded transition-all duration-200";
  const { user } = useContext(AuthContext);

//test
  useEffect(() => {
    setSound(initialSound);
    // Restore the player view when a new track is played
    setIsMinimized(false);
  }, [initialSound]);

  useEffect(() => {
    if (sound) {
      setIsLiked(sound.is_liked || false);
    }
  }, [sound]);

  // Control Lottie animation based on audio playback state
  useEffect(() => {
    // Add a small delay to ensure the Lottie animation is fully initialized
    const timer = setTimeout(() => {
      if (lottieRef.current) {
        if (isPlaying) {
          lottieRef.current.play();
        } else {
          lottieRef.current.stop();
        }
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, [isPlaying, isMinimized]);

  const handlePlayPause = async () => {
    if (onPlayPause) {
      onPlayPause();
    }
    Analytics.trackSoundPlay(sound);
  };

  const handleLicenseSuccess = async (updatedSound) => {
    setSound(updatedSound);
    if (continueToPurchase) {
      processPurchase();
      setContinueToPurchase(false);
    }
    if (continueToDownload) {
      handleDownload();
      setContinueToDownload(false);
    }
    Analytics.trackLicenseAccept(sound, sound.license);
  };

  const handleLike = async () => {
    if (!sound) return;
    
    const newLikedState = !isLiked;
    setIsLiked(newLikedState);
    
    try {
      if (newLikedState) {
        await likeSound(sound.id);
        sound.is_liked = true;
      } else {
        await unlikeSound(sound.id);
        sound.is_liked = false;
      }
      Analytics.trackSoundLike(sound, newLikedState);
    } catch (error) {
      setIsLiked(!newLikedState);
      setSound(prevSound => ({
        ...prevSound,
        is_liked: !newLikedState
      }));
      console.error('Failed to update like status:', error);
    }
  };

  const processPurchase = async () => {
    setIsPurchasing(true);
    try {
      await purchaseSound(sound.id);
      const updatedSound = await fetchSoundById(sound.id);
      setSound(updatedSound);
      Analytics.trackSoundPurchase(sound);
      if (continueToDownload) {
        handleDownload();
      }
    } catch (error) {
      console.error('Failed to purchase sound:', error);
    } finally {
      setIsPurchasing(false);
    }
  };

  const handlePurchase = async (e) => {
    if (e) e.stopPropagation();
    if (!sound || sound.is_purchased) return;
    
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToPurchase(true);
      return;
    }
    processPurchase();
  };

  const downloadFile = async (url, filename = 'sound.wav') => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobURL = URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = blobURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading file:', error);
      throw error;
    }
  };

  const handleDownload = async (e) => {
    if (e) e.stopPropagation();
    if (!sound || !sound.is_purchased) return;
    
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToDownload(true);
      return;
    }

    setIsDownloading(true);
    try {
      const downloadUrl = await getDownloadURL(sound.id);
      if (downloadUrl) {
        const soundDetails = await fetchSoundById(sound.id);
        const fileName = soundDetails.file_name.substring(soundDetails.file_name.lastIndexOf('/') + 1);
        await downloadFile(downloadUrl, fileName);
        Analytics.trackSoundDownload(sound);
      }
    } catch (error) {
      console.error('Failed to download sound:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const handleJUCEReceive = ({ eventName, eventData }) => {
      // This listener now only handles position and duration updates
      // The dragExportComplete event is handled separately in the handleDragStart function
      if (eventName === 'audioPlayerPosition') {
        lastJUCEPosition.current = eventData;
        
        if (waveSurferRef.current) {
          waveSurferRef.current.seekTo(eventData);
          const newTime = eventData * duration;
          setCurrentTime(newTime);
        }
      }
      if (eventName === 'audioPlayerDuration') {
        setDuration(eventData);
      }
    };

    JUCEReceive.on('JUCEReceive', handleJUCEReceive);

    let timeUpdateInterval;
    if (isJUCE() && isPlaying) {
      timeUpdateInterval = setInterval(() => {
        setCurrentTime(prevTime => {
          return lastJUCEPosition.current * duration;
        });
      }, 100);
    }

    return () => {
      JUCEReceive.off('JUCEReceive', handleJUCEReceive);
      if (timeUpdateInterval) {
        clearInterval(timeUpdateInterval);
      }
    };
  }, [duration, isPlaying]);

  useEffect(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.destroy();
      } catch (error) {
        console.error("Error while destroying WaveSurfer:", error);
      }
    }
  
    const initWaveSurfer = () => {
      if (!sound || !sound.audio_preview) return;
  
      const primaryColorElement = document.createElement('div');
      primaryColorElement.className = 'text-text-primary hidden';
      document.body.appendChild(primaryColorElement);
      const secondaryColorElement = document.createElement('div');
      secondaryColorElement.className = 'text-text-secondary hidden';
      document.body.appendChild(secondaryColorElement);
  
      const primaryColor = getComputedStyle(primaryColorElement).color;
      const secondaryColor = getComputedStyle(secondaryColorElement).color;
  
      document.body.removeChild(primaryColorElement);
      document.body.removeChild(secondaryColorElement);
  
      if (!waveformRef.current) return;
      
      waveSurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: secondaryColor,
        progressColor: '#B721FF', // Purple progress bar
        height: 48, // Match the container height
        responsive: true,
        barWidth: 2,
        barGap: 1,
        cursorWidth: 0,
        hideScrollbar: true,
        backend: 'MediaElement',
        fillParent: true,
        loop: true,
        normalize: true, // Normalize for better visualization
        barRadius: 0,
        waveColor: 'rgba(255, 255, 255, 0.3)',
      });
  
      const urlWithEncodedHash = sound.audio_preview.replace(/#/g, '%23');
      waveSurferRef.current.load(urlWithEncodedHash);
  
      waveSurferRef.current.on('ready', () => {
        const waveSurferDuration = waveSurferRef.current.getDuration();
        setDuration(waveSurferDuration);
        if (isJUCE()) {
          JUCESend('audioPlayerDuration', waveSurferDuration);
        }
        setIsWaveSurferReady(true);
        if (isPlaying && !isJUCE()) waveSurferRef.current.play();
        waveSurferRef.current.setVolume(volume / 100);
        
        if (waveSurferRef.current.backend && waveSurferRef.current.backend.media) {
          waveSurferRef.current.backend.media.loop = true;
        }
      });
  
      waveSurferRef.current.on('finish', () => {
        if (!isJUCE() && sound.type.name === 'Loop') {
          waveSurferRef.current.seekTo(0);
          if (isPlaying) {
            waveSurferRef.current.play();
          }
        }
      });
  
      waveSurferRef.current.on('audioprocess', () => {
        if (!isJUCE()) {
          setCurrentTime(waveSurferRef.current.getCurrentTime());
        }
      });
    };
  
    const timer = setTimeout(initWaveSurfer, 100);
  
    return () => {
      clearTimeout(timer);
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.destroy();
        } catch (error) {
          console.error("Error while destroying WaveSurfer on cleanup:", error);
        }
      }
    };
  }, [sound, onEnded, isPlaying]);

  useEffect(() => {
    if (isWaveSurferReady && !isJUCE() && waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.play();
      } else {
        waveSurferRef.current.pause();
      }
    }
  }, [isPlaying, isWaveSurferReady]);

  useEffect(() => {
    if (waveSurferRef.current && !isJUCE()) {
      try {
        waveSurferRef.current.setVolume(volume / 100);
      } catch (error) {
        console.error("Error while setting volume:", error);
      }
    }
  }, [volume]);

  const handleSeek = (e) => {
    if (!waveSurferRef.current) return;
    
    const seekPosition = e.nativeEvent.offsetX / e.target.clientWidth;
    
    if (isJUCE()) {
      JUCESend('audioPlayerSeek', seekPosition);
      setCurrentTime(seekPosition * duration);
      lastJUCEPosition.current = seekPosition;
    } else {
      waveSurferRef.current.seekTo(seekPosition);
      setCurrentTime(seekPosition * duration);
    }
  };

  // Simple event handler for compatibility, actual implementation now in ActionButton
  const handleDragStart = (e) => {
    // This is now just a pass-through handler as the logic is in ActionButton
    // No state changes or async operations here
  };
  
  const handleGenerateClick = () => {
    if (!promptText.trim() && !sound) return;
    
    // Open the AISampleDialog with the current sound and prompt
    setIsAISampleDialogOpen(true);
    
    // Optional: Reset generating state
    // Note: The actual generation will be handled by AISampleDialog
    if (isGenerating) {
      setIsGenerating(false);
    }
  };
  
  const handleAISampleDialogClose = () => {
    setIsAISampleDialogOpen(false);
  };

  // Calculate the offset for centering with respect to the navigation bar
  const [navBarWidth, setNavBarWidth] = useState(0);

  // Listen for navigation bar width changes
  useEffect(() => {
    const updateNavBarWidth = () => {
      // Try to find the navigation bar element
      const navBar = document.querySelector('nav');
      if (navBar) {
        setNavBarWidth(navBar.offsetWidth);
      }
    };

    // Initial calculation
    updateNavBarWidth();

    // Set up a resize observer to detect changes in the navigation bar width
    const resizeObserver = new ResizeObserver(updateNavBarWidth);
    const navBar = document.querySelector('nav');
    if (navBar) {
      resizeObserver.observe(navBar);
    }

    // Listen for the custom navStateChange event for immediate updates
    const handleNavStateChange = (event) => {
      // Update the width immediately without waiting for React's state update
      const newWidth = event.detail.width;
      setNavBarWidth(newWidth);
      
      // Also directly update the DOM element for immediate visual feedback
      const audioPlayerElement = document.querySelector('.audio-player-container');
      const isMobileView = window.innerWidth < 768; // Match Tailwind's md breakpoint
      
      if (audioPlayerElement) {
        // Only apply the offset on desktop devices
        audioPlayerElement.style.left = isMobileView ? '50%' : `calc(50% + ${newWidth / 2}px)`;
      }
    };
    document.addEventListener('navStateChange', handleNavStateChange);

    // Also listen for window resize events as a fallback
    window.addEventListener('resize', updateNavBarWidth);

    return () => {
      if (navBar) {
        resizeObserver.unobserve(navBar);
      }
      document.removeEventListener('navStateChange', handleNavStateChange);
      window.removeEventListener('resize', updateNavBarWidth);
    };
  }, []);

    return (
      <>
        {/* Full Audio Player */}
      {!isMinimized && (
        <div 
          className={`audio-player-container fixed ${isJUCE() ? 'bottom-20' : isMobile ? 'bottom-16' : 'bottom-8'} z-[100] transition-all duration-300 ease-in-out bg-bg-secondary/40 backdrop-blur-md border border-white/10 shadow-lg rounded-xl overflow-hidden`}
          style={{ 
            height: `${isMobile ? MOBILE_PLAYER_HEIGHT + 48 + (user.admin ? 48 : 0) : DESKTOP_PLAYER_HEIGHT + 48 + (user.admin ? 48 : 0)}px`, /* Base height + waveform + prompt input (if admin) */
            width: isMobile ? '95%' : `${PLAYER_WIDTH}px`,
            // Center the player in the available space, accounting for the navigation bar only on desktop
            left: isMobile ? '50%' : `calc(50% + ${navBarWidth / 2}px)`,
            transform: 'translateX(-50%)',
            maxWidth: '95vw'
          }}
        >


  
      {/* Minimize button positioned in the top left corner */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          // Only change visual state, don't affect playback
          setIsMinimized(!isMinimized);
          
          // Ensure playback continues if it was already playing
          if (isPlaying && isWaveSurferReady && waveSurferRef.current && !isJUCE()) {
            setTimeout(() => {
              waveSurferRef.current.play();
            }, 50);
          }
        }}
        className="absolute top-1 left-1 p-1 hover:bg-white/10 rounded-full z-10 transition-colors"
        aria-label={isMinimized ? "Expand player" : "Minimize player"}
      >
        <ChevronDown className="w-4 h-4 text-text-secondary" />
      </button>

      {/* Main player container */}
      <div className="h-full w-full">
        {isMinimized ? (
          /* Minimized player view */
          <div className="flex items-center justify-between px-4 py-3 h-full">
            <div className="flex items-center space-x-3">
              <button
                onClick={handlePlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-1.5 rounded-full flex-shrink-0"
              >
                {isPlaying ? <PauseIcon className="w-4 h-4" /> : <PlayIcon className="w-4 h-4" />}
              </button>
              <div className="flex items-center">
                <img 
                  src={sound?.image || DEFAULT_SOUND_IMAGE} 
                  alt={sound?.name} 
                  className="w-6 h-6 object-cover rounded-sm mr-2"
                />
                <div className="text-sm font-medium text-text-primary truncate max-w-[200px]">
                  {sound?.name}
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <button onClick={onPrevious} className="p-1">
                <PrevIcon />
              </button>
              <button onClick={onNext} className="p-1">
                <NextIcon />
              </button>
            </div>
          </div>
        ) : (
          /* Full player view */
          <div className="px-2 md:px-6 py-4">
          {/* Mobile view - horizontal layout matching screenshot */}
          <div className="md:hidden flex items-center justify-between px-2 py-2">
            <div className="flex items-center space-x-1">
              <button onClick={onPrevious} className="p-1">
                <PrevIcon />
              </button>
              <button onClick={handlePlayPause} className="bg-gradient-to-r from-accent-start to-accent-end p-2 rounded-full flex-shrink-0">
                {isPlaying ? (
                  <svg className="w-5 h-5 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                  </svg>
                ) : (
                  <svg className="w-5 h-5 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M8 5v14l11-7z"/>
                  </svg>
                )}
              </button>
              <button onClick={onNext} className="p-1">
                <NextIcon />
              </button>
            </div>

            {/* Album art and filename in center */}
            <div className="flex items-center flex-grow mx-2 min-w-0 overflow-hidden">
              <div className="w-8 h-8 relative mr-2 flex-shrink-0">
                <img 
                  src={sound?.image || DEFAULT_SOUND_IMAGE} 
                  alt={sound?.name} 
                  className="w-8 h-8 object-cover rounded-sm"
                />
              </div>
              <div className="min-w-0 flex-grow overflow-hidden">
                <div className="text-sm font-semibold text-text-primary truncate">
                  {sound?.name}
                </div>
                <div className="text-xs text-text-secondary truncate">
                  {sound?.owner?.username}
                </div>
              </div>
            </div>
              
            <div className="flex items-center space-x-1 flex-shrink-0">
              <button
                onClick={handleLike}
                className="p-1"
              >
                <LikeIcon filled={isLiked} />
              </button>

              <ActionButton 
                sound={sound}
                isPurchasing={isPurchasing}
                isDownloading={isDownloading}
                onPurchase={handlePurchase}
                onDownload={handleDownload}
                isJUCE={isJUCE()}
                className=""
              />
            </div>
          </div>
  
          {/* Desktop view (728px and above) */}
          <div className="hidden md:flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <button onClick={onPrevious} className="p-1">
                <PrevIcon />
              </button>
              <button
                onClick={handlePlayPause}
                className="bg-gradient-to-r from-accent-start to-accent-end text-white p-2 rounded-full hover:shadow-glow transition-all duration-200"
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </button>
              <button onClick={onNext} className="p-1">
                <NextIcon />
              </button>
            </div>
  
            <div className="flex items-center space-x-4 flex-grow min-w-0">
              <div className="w-14 relative flex-shrink-0">
                <div className="absolute inset-0 bg-black/20 rounded-lg blur-md transform"></div>
                <img 
                  src={sound?.image || DEFAULT_SOUND_IMAGE} 
                  alt={sound?.name} 
                  className="w-14 h-14 object-cover rounded-lg relative z-10 border border-white/10"
                />
              </div>
  
              <div className="flex-grow mr-1 min-w-0 max-w-md">
                <div className="text-sm font-semibold text-text-primary truncate w-full">
                  {sound?.name}
                </div>
                <div className="text-xs text-text-secondary truncate">
                  {sound?.owner?.username}
                </div>
              </div>
            </div>
  
            <div className="flex items-center space-x-1">
              <button
                onClick={handleLike}
                className="p-1 hover:bg-white/10 rounded-full transition-colors"
              >
                <LikeIcon filled={isLiked} />
              </button>
  
              {!isJUCE() && (
                <div className="hidden md:block">
                  <VolumeControl 
                    volume={volume}
                    onChange={(newVolume) => {
                      setVolume(newVolume);
                      if (waveSurferRef.current && !isJUCE()) {
                        waveSurferRef.current.setVolume(newVolume / 100);
                      }
                    }}
                    isMobile={false}
                  />
                </div>
              )}
  
              <ActionButton 
                sound={sound}
                isPurchasing={isPurchasing}
                isDownloading={isDownloading}
                onPurchase={handlePurchase}
                onDownload={handleDownload}
                isJUCE={isJUCE()}
                className=""
              />
            </div>
          </div>
          </div>
        )}
      </div>


      
      {/* Waveform positioned at the bottom of the player - only show when not minimized */}
      {!isMinimized && (
        <>
          <div 
            className={`absolute ${user.admin === true ? 'bottom-[48px]' : 'bottom-0'} left-0 right-0 cursor-pointer`}
            style={{ 
              height: '48px',
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }}
            ref={waveformRef}
            onClick={handleSeek}
          />
                      {/* Text field and Generate button under waveform */}
          {user.admin === true && (

          <div className="absolute bottom-0 left-0 right-0 px-2 sm:px-4 py-2 bg-bg-secondary/95 border-t border-white/10">
          <div className="flex items-center space-x-1 sm:space-x-2">
            <input
              type="text"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
              placeholder={isMobile ? "Enter prompt..." : "Enter text prompt for audio generation..."}
              className="flex-grow px-2 sm:px-3 py-2 h-9 bg-white/10 border border-white/20 rounded-lg text-sm text-text-primary focus:outline-none focus:ring-1 focus:ring-accent-start text-ellipsis"
            />
            <button
              onClick={handleGenerateClick}
              disabled={isGenerating || !promptText.trim()}
              className={`${isMobile ? 'p-2' : 'px-2 sm:px-4'} h-9 rounded-lg font-medium text-sm whitespace-nowrap transition-all duration-200 ${isGenerating || !promptText.trim() ? 'bg-white/10 text-text-secondary cursor-not-allowed' : 'bg-gradient-to-r from-accent-start to-accent-end text-white hover:shadow-glow'}`}
            >
              {isGenerating ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Generating
                </span>
              ) : isMobile ? (
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  {/* Classic magic wand with sparkles */}
                  
                  {/* Main wand */}
                  <path d="M8 16L16 8L18 10L10 18L8 16Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16 8L19 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  
                  {/* Big sparkle top left */}
                  <path d="M7 4V7" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                  <path d="M5.5 5.5H8.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                  
                  {/* Big sparkle bottom right */}
                  <path d="M17 17V20" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                  <path d="M15.5 18.5H18.5" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                  
                  {/* Small sparkles */}
                  <path d="M5 12L4 13L5 14L6 13L5 12Z" fill="currentColor" />
                  <path d="M20 10L19 11L20 12L21 11L20 10Z" fill="currentColor" />
                  <path d="M12 19L11 20L12 21L13 20L12 19Z" fill="currentColor" />
                </svg>
              ) : 'Generate'}
            </button>
          </div>
        </div>
          )}


        </>
      )}
  
      {/* License Modal */}
      <SoundCardDialogs
        sound={sound}
        isLicenseModalOpen={isLicenseModalOpen}
        setIsLicenseModalOpen={setIsLicenseModalOpen}
        isReportDialogOpen={isReportDialogOpen}
        setIsReportDialogOpen={setIsReportDialogOpen}
        onLicenseSuccess={handleLicenseSuccess}
      />
        </div>
      )}
      
      {/* Minimized Player Control - only visible when player is minimized */}
      {isMinimized && (
        <div 
          className="fixed bottom-3 bg-gradient-to-r from-accent-start to-accent-end rounded-full p-2 shadow-lg cursor-pointer transition-all duration-200 hover:scale-105 hover:shadow-glow"
          style={{
            right: 'calc(6rem + 80px)', // Moved 100px to the left from its original position
            zIndex: 1000000 // Extremely high z-index value to absolutely ensure it's above all other elements
          }}
          onClick={() => {
            setIsMinimized(false);
            
            // Ensure playback continues if it was already playing
            if (isPlaying && isWaveSurferReady && waveSurferRef.current && !isJUCE()) {
              setTimeout(() => {
                waveSurferRef.current.play();
              }, 50);
            }
          }}
          title="Restore audio player"
        >
          <div className="w-8 h-8">
            <Lottie lottieRef={lottieRef} animationData={waveformMinimizeAnimation} loop={true} autoplay={false} />
          </div>
        </div>
      )}
      
      {/* AI Sample Dialog for audio generation */}
      {sound && (
        <AISampleDialog 
          isOpen={isAISampleDialogOpen} 
          onClose={handleAISampleDialogClose} 
          sound={sound}
          initialPrompt={promptText}
          autoGenerate={true} 
        />
      )}
      </>
    )
};

export default AudioPlayer;